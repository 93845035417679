.app-container {
    display: flex;
    flex-direction: column;

  }
  
  table {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  
  th,
  td {
    text-align: left;
    padding: 6px;
    font-size: 35px;
    color: black;
  }
  
  th {
    border: 2px solid black;
    background-color: rgb(0,68,102);
    color: white;
  }
  
  td {
    border: 2px solid white;
    background-color: rgb(149, 191, 218);
  }
  
  form {
    display: flex;
    gap: 5px;
  }
  
  /* form td:last-child {
    
  } */
  
  form * {
    font-size: 20px;
  }
