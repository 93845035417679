* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-container {
  display: flex;
  min-height: 100vh;
}
main {
  position: relative;
  min-height: auto;
  
}

/* Sidebar */
.sidebar {
  background: rgb(0,68,102);
  color: white;
  position: relative;
  min-width: 300px;
  border-radius: 0 15px 15px 0;
  margin-bottom: -55px;
  margin-top: .5em;
  max-height: auto;
  min-height: 100vh;
}

.top_section {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 30px;
  padding: 25px 10px;
}

.routes {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 22px;
  font-size: 20px;
}
.link {
  display: flex;
  color: white;
  gap: 15px;
  padding: 5px 10px;
  border-right: 4px solid transparent;
}
.link:hover {
  border-right: 4px solid white;
  background: rgb(45, 51, 89);
}
.active {
  border-right: 4px solid white;
  background: rgb(45, 51, 89);
}
.link_text {
  font-size: 20px;
  display: flex;
  
}

.menu {
  display: flex;
  color: white;
  padding: 5px 10px;
  border-right: 4px solid transparent;
  justify-content: space-between;
}
.icon {
  font-size: 30px;
}
.menu_item {
  display: flex;
  gap: 10px;
}
.menu_container {
  display: flex;
  width: 300px;
  height: 100vh;
  flex-direction: column;
  /*top: 0;*/
}

.menu_container.fixed {
  position: fixed;
  top: 0; /* Adjust this value as needed */
}

.menu_container .link {
  padding-left: 15px;
  font-size: 15px;
}
.submenu_container {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}


.link2 {
  display: flex;
  align-items: center;
  padding: 8px 16px; /* Adjust the padding as needed */
}
.link2:hover {
  border-right: 4px solid white;
  background: rgb(45, 51, 89);
}

.link_text2 {
  margin-left: 8px; 
  font-size: 17px;
  color: white;
}
.link:not(.server-setup):active,
.link:not(.server-setup).active {
  background-color: transparent;
  color: inherit;
  text-decoration: none;
  border-right: none;
}