
body {
    margin: 0;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    font-family: "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    /* -webkit-font-smoothing: antialiased; */
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  
  ul {
    list-style: none;
  }
  li {
    list-style: none;
  }
  a {
    text-decoration: none;
  }